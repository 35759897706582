import React from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import "normalize.css"
import "../styles/main.scss"
import Header from "../components/header";
import Transition from "../components/transition";

const PageLayout = ({pageProps, pageEl}) => <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
        const {location} = pageProps
        const {title} = data.site.siteMetadata
        return (
        <div id="site" className="site">
            <Header location={location} siteTitle={title} headerTheme={'default'}/>
            <main><Transition key='transition' location={location}>{pageEl}</Transition></main>
            <footer>
                <div className="col">
                    <span>© {new Date().getFullYear()}, Philippe Roux Architectes Associés</span>
                </div>
                <div className="col col--right">
                    <Link className={`terms`} to={`/mentions-legales`} >Mentions Légales</Link>
                </div>
            </footer>
        </div>
    )}}
/>

export default PageLayout
