import NavLink from "./nav-link"
import PropTypes from "prop-types"
import React from "react"
import {Link} from "gatsby"
import Logo from "./logo";
//import { globalHistory } from '@reach/router';

let scrollBackup = 0;

class Header extends React.Component{

    toggleNav(event){
        let htmlClasslist = window.document.documentElement.classList,
            displayMenu = !htmlClasslist.contains('mobile-nav-toggle');
        if(displayMenu){
            scrollBackup = window.scrollY;
        }
        htmlClasslist.toggle('mobile-nav-toggle');
        if(displayMenu){
            window.document.body.scrollTop = scrollBackup;
            console.log('window.document.body.scrollTop = scrollBackup',scrollBackup);
        }else{
            window.scroll(0,scrollBackup);
            //window.scrollY = scrollBackup;
            //console.log(scrollBackup,window.scrollY)
        }

    }

    render() {
    let theme;
    switch(this.props.location.pathname){
        case '/':
            theme = 'introduction';
            break;
        default:
            theme = 'default';
    }

    return (
            <header className={`header header--${theme}`}>
                <div className={'container'}>
                    <h1 style={{ margin: 0 }}>
                        <Link to={"/tous-les-projets"}>
                            <Logo className={"logo--header"} />
                        </Link>
                    </h1>
                    <button onClick={this.toggleNav.bind(this)} className={'toggle-nav'} type={'button'}><span>Menu</span> </button>
                    <nav>
                        <NavLink id="nav-home" to={"/tous-les-projets"}>TOUS LES PROJETS </NavLink>
                        <NavLink id="nav-construction" to={"/construction"}>CONSTRUCTION </NavLink>
                        <NavLink id="nav-renovation" to={"/renovation"}>RÉNOVATION </NavLink>
                        <NavLink id="nav-agence" to={"/agence"}>AGENCE </NavLink>
                        <button type="button" className={'placeholder'} onClick={this.toggleNav.bind(this)} >&nbsp;</button>
                    </nav>
                </div>
            </header>
    )}
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
